import React from 'react'
import image from '../content/image.jpg'
import Footer from './Footer'

function Main(){
    return(
        <div>
            <div className="my-intro">
                <div className="greet">
                    <h1 style={{color:"#ABABAB"}}>Hello!</h1>
                    <p>My name is Austin, I'm a junior web-developer with passion for chasing projects that pique my interest.</p>
                    <p>With the time i've spent studying, i've become adept at using things like React, Mongoose, and Javascript. I'm also ready and willing to be part of a team as well as learn any new skillsets needed to get a job done.</p>
                    <p>I’ve always been creative and technical. As a creative problem solver, web development is the perfect fit. Whether I’m writing a story, drawing, or analyzing a game, I’m always intrigued by the complexities of how gaming works and love it when boundaries are pushed to the next level. I’m at my best when I’m helping other storytellers get their stories out to the world.</p>
                </div>
                <img src={image} alt="whoops"/>
                <div className="links">
                    <a href="https://docs.google.com/document/d/1dk1Q__jStKk1HeiOEtEUSJjjerRlRdffvkBFjmNiATQ/edit?usp=sharing" target="_blank" rel="noopener noreferrer"> Here's My Resume </a>
                    <a href="https://www.linkedin.com/in/austinbyas" target="_blank" rel="noopener noreferrer"> Visit My LinkedIn </a>              
                    <a href="https://github.com/Aeroflou" target="_blank" rel="noopener noreferrer"> Check Out My GitHub </a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Main