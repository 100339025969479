import React from 'react'
import Email from './Email'
//import ProjectScroller from './ProjectScroller'

function Footer(){
    return(
        <footer>
            <div className="projectsScroller">
                <img alt="Hello! This is a Placeholder!" src="https://www.outsidenomad.com/wp-content/uploads/2022/01/Machu-PIcchu-and-Cusco-comparison-2022-01-14T215247.672.png" style={{height:"100%", width:"100%", borderRadius:"10px"}}/>
            </div>
            {/*<ProjectScroller classname="projectsScroller" />*/}
            <Email className="emailForm" />
        </footer>
    )
}

export default Footer