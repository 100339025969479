import React, { useContext } from  'react'
import { BattleContext } from '../context/BattleProvider'
import BattleTest from './BattleTest'
import UnitList from './UnitList'

export default function BattleScreen(){
    const { units } = useContext(BattleContext)
    return(
        <div style={{fontFamily:"'Times New Roman', Times, serif", color:"#080808", backgroundColor:"#222222"}}>
            <BattleTest unitA={units[1]} unitB={units[2]} />
            <h1 style={{textAlign:"center"}}>Equip & View Units</h1>
            <div>
                <UnitList />
            </div>
        </div>
    )
}
//<button>Resolve Battle</button>
