import React from 'react'
import EmailForm from './EmailForm'

export default function Email(){
    return(
        <div>
            <h3>Need to get in touch? Email me!</h3>
            <p>austi.awo@gmail.com</p>
            <EmailForm />
        </div>
    )
}