import React, { useState, useContext, useEffect } from 'react'
import { BattleContext } from '../context/BattleProvider'
import EquipGear from './EquipGear'

export default function Unit(props){
    const { unit, selectList, setUnit, unitSelects } = props
    const { weapons, armor, equipWeapon, equipArmor } = useContext(BattleContext)
    const [button, toggleButton] = useState(!selectList)
    const [elHeight, changeElHeight] = useState(280)
    useEffect(() => {
        button ? changeElHeight(280) : changeElHeight(100)
    }, [button, selectList])
    return(
        <div style={{display:"grid", padding:0, gridTemplateRows:"20px 80px 180px", gridTemplateColumns:"1fr 1fr", width:"98%", height:elHeight, 
        border:"5px solid #141466", backgroundColor:"#2929CC", margin:0, borderRadius:'50px 15px 50px 15px'}}>
            <ul style={{gridRow:"1/2", gridColumn:"1/3", height:20, borderBottom:"3px solid #141466", display:"flex", alignItems:"center", listStyle:"none"}}>
                <h2 style={{position:"relative", bottom:"5px"}}>{unit.name}</h2> 
                <p style={{position:"relative", bottom:"5px"}}>|</p>
                {!selectList && <button style={{position:"relative", bottom:"5px"}} onClick={() => toggleButton(!button)}>{button ? "Close General Stats & Equip" : "Open General Stats & Equip"}</button>}
                {selectList && <>
                {!unitSelects.selected[0] || unit._id === unitSelects.unitA ? <button style={{position:"relative", bottom:"5px"}} onClick={() => setUnit(unit, 1)}>{!unitSelects.selected[0]  ? "Select As Unit 1" : "Unselect Unit 1"}</button> : <></>}
                {!unitSelects.selected[1] || unit._id === unitSelects.unitB ? <button style={{position:"relative", bottom:"5px"}} onClick={() => setUnit(unit, 2)}>{!unitSelects.selected[1]  ? "Select As Unit 2" : "Unselect Unit 2"}</button> : <></>}
                </>}
            </ul>
            <div style={{gridRow:"2/3", gridColumn:"1/3", height:80, display:"grid", gridTemplateColumns:"1fr 1fr", gridTemplateRows:"30px 50px", paddingLeft: 2}}>
                <h3 style={{gridRows:"1/2", gridColumn:"1/3"}}>WEAPON: {unit.inventory.equip.weapon.name === "" ? "N/A" : unit.inventory.equip.weapon.name} - ARMOR: {unit.inventory.equip.armor.name === "" ? "N/A" : unit.inventory.equip.armor.name}</h3>
                <h3 style={{gridRows:"2/3", gridColumn:"1/2", fontSize:16}}>HP:{unit.prclcstats.efhp} | DEF:{unit.prclcstats.efrsl} | RES:{unit.prclcstats.efwrd} <br /> ATK:{unit.prclcstats.atk} | ASPD:{unit.prclcstats.aspd < 0 ? 0 : unit.prclcstats.aspd}</h3>
                <p style={{gridRows:"2/3", gridColumn:"2/3", fontSize:14}}>HIT:{unit.prclcstats.hit} | AVO:{unit.prclcstats.avo} | DGE:{unit.prclcstats.dge} <br /> CRIT:{unit.prclcstats.crit} | CRITDMG:{unit.prclcstats.critdmg}</p>
            </div>
            {button && !selectList ?
            <div style={{gridRow:"3/4", gridColumn:"1/3", height:180, display:"grid", gridTemplateColumns:"4fr 3fr 3fr", borderTop: "3px solid #141466", paddingLeft: 2}}>
                <div style={{gridColumn:"1/2", borderRight:"3px solid #141466"}}>
                    <h3>HP:{unit.gstats.bshp}({unit.prclcstats.efhp}) RSL:{unit.gstats.rsl}({unit.prclcstats.efrsl}) WRD:{unit.gstats.wrd}({unit.prclcstats.efwrd})</h3>
                    <p>STR:{unit.gstats.str} | MAG:{unit.gstats.mag} <br /> SPD:{unit.gstats.spd} | DEX:{unit.gstats.dex}</p>
                    <p>CHA:{unit.gstats.cha} | INT:{unit.gstats.gint} | PER:{unit.gstats.per} LCK:{unit.gstats.lck} | WLP:{unit.gstats.wlp} | CON:{unit.gstats.con}</p>
                </div>
                <EquipGear style={{gridColumn:"2/3", border:"3px solid blue"}} unit={unit} weapons={weapons} equipWeapon={equipWeapon} wepArm={"weapon"} />
                <EquipGear style={{gridColumn:"3/4", border:"3px solid blue"}} unit={unit} armor={armor} equipArmor={equipArmor} wepArm={"armor"} />
            </div>
            :<></>
            }
        </div>
    )
}