import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Navigation from './components/Navigation'
import Main from './components/Main'
import Projects from './components/Projects'
import BattleScreen from './battle_test/BattleScreen'
//import Footer from './components/Footer'

function App(){
    return(
        <div>
            <Navigation />
            <Routes>
                <Route 
                    path="/" 
                    element={<Main />} 
                />
                <Route 
                    path="/projects" 
                    element={<Projects />} 
                />
                <Route 
                    path="/projects/battleTest"
                    element={<BattleScreen />}
                />
                {/*<Route
                    path="/contact"
                    element={<Contact />}
                />*/}
            </Routes>
            {/*<Footer />*/}
        </div>
    )
}

export default App