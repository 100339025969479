import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import BattleProvider from './context/BattleProvider'
import './styles.css'

ReactDOM.render(
    <BrowserRouter>
        <BattleProvider>
            <App/>
        </BattleProvider>    
    </BrowserRouter>
, document.getElementById('root'))