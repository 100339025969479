import React from 'react'
import { Link } from 'react-router-dom'

function Projects(){
    return(
        <div style={{paddingLeft:"10px",paddingTop:"5px"}}>
            <h1> Here are a few of the projects I've contributed to </h1>
            <div className="project">
                <h3>
                    <Link to="/projects/battleTest"> Battle Project </Link>
                </h3>
                <p>This is a personal project I've made to serve as a very rough concept of a mechanic for a game I'm working on. Be aware that at the moment the CSS is very likely to break with smaller screens.</p>
            </div>
            <br/>
            <div className="project">
                <h3>
                    <a href="https://research-needs-00.web.app/" target="_blank" rel="noopener noreferrer"> Research Needs </a>
                </h3>
                <p>This project is as of this moment, unfinished. Though i've made some contributions, namely in a few design tweaks and some of the technical aspects on the about page. As well as some of the same on the contact page, though more pressingly linking the form itself to a service.</p>
            </div>
        </div>
    )
}

export default Projects