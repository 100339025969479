import React, {useContext, useState} from 'react'
import {BattleContext} from '../context/BattleProvider'
import Unit from './Unit'

export default function UnitList(props) {
    const {setSlctedUnits} = props
    const {units} = useContext(BattleContext)
    const initUnit = {
        name: "N/A",
        gstats: {bshp: 0, str: 0, spd: 0, dex: 0, rsl: 0,mag: 0, wrd: 0, gint: 0, cha: 0, con: 0, per: 0, wlp: 0, lck: 0 },
        prclcstats: {efhp: 0, hit: 0, crit: 0, avo: 0, dge: 0 },
        inventory: { equip: {
                weapon: { mt: 0, hit: 0, crit: 0, rng: 1, wt: 0, efwt: 0, eff: {}, name: "" }, 
                armor: { val: 1, bsval: 0, wt: 0, wtty: "", eff: {}, name: "" }, 
                accessory: { wt: 0, eff: {}, name: "" }
            },bag: {
                slot1: { type: "", item: {} }, 
                slot2: { type: "", item: {} }, 
                slot3: { type: "", item: {} }
            },acrrwt: 0
        }, _id: null
    }    
    const [unitSelects, setUnitSelects] = useState({
        unitA: initUnit,
        unitB: initUnit,
        selected: [false, false]
    })
    function setUnit(unit, selectNum){
        if(selectNum === 1){
            if(unitSelects.selected[0]){
                setUnitSelects(prevState => ({
                    ...prevState,
                    unitA: initUnit,
                    selected: [false, prevState.selected[1]]
                }))
                setSlctedUnits(prevState => ({
                    ...prevState,
                    unitA: initUnit
                }))
            } else {
                setUnitSelects(prevState => ({
                    ...prevState,
                    unitA: unit._id,
                    selected: [true, prevState.selected[1]]
                }))
                setSlctedUnits(prevState => ({
                    ...prevState,
                    unitA: unit
                }))
            }
        } else {
            if(unitSelects.selected[1]){
                setUnitSelects(prevState => ({
                    ...prevState,
                    unitB: initUnit,
                    selected: [prevState.selected[0], false]
                }))
                setSlctedUnits(prevState => ({
                    ...prevState,
                    unitB: initUnit
                }))
            } else {
                setUnitSelects(prevState => ({
                    ...prevState,
                    unitB: unit._id,
                    selected: [prevState.selected[0], true]
                }))
                setSlctedUnits(prevState => ({
                    ...prevState,
                    unitB: unit
                }))
            }
        }
    }

    const unitSelectList = units.map(unit => <Unit key={unit._id} unit={unit} selectList={true} setUnit={setUnit} unitSelects={unitSelects}/>)
    return(
        <div style={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr", width:"auto", backgroundColor:"#121212"}}>
            {unitSelectList}
        </div>
    )
}