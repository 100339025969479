import React, { useState } from 'react'
import emailjs from 'emailjs-com'

export default function EmailForm(){
    const initForm = {name: "", email: "", message: ""}
    const [inputs, setInputs] = useState(initForm)

    const inputStyle = {
        paddingLeft:"3px",
        borderRadius:"3px",
        border:"1px solid #D3D3D3",
        height:"30px",
        width:"80%",
        fontSize:"16px"
    }

    function handleChange(e){
        const { name, value } = e.target
        setInputs((prevState) => {
          return {
            ...prevState,
            [name]: value,
          }
        })
    }
    function handleSubmit(e){
        e.preventDefault()
        emailjs.sendForm(
          process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            alert('Your message has been properly sent!');
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        )
    }

    const {name, email, message} = inputs
    return(
        <div>
            <form onSubmit={handleSubmit}>
                <h3>Name</h3>
                <input
                    style={inputStyle}
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    placeholder="Name"
                />
                <br/>
                <h3>Email</h3>
                <input
                    style={inputStyle}
                    type="text"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    placeholder="Email"
                />
                <br/>
                <h3>Message</h3>
                <textarea
                    style={{
                        paddingLeft:"3px",
                        borderRadius:"3px",
                        border:"1px solid #D3D3D3",
                        height:"80px",
                        width:"80%",
                        fontSize:"16px"
                    }}
                    name="message"
                    value={message}
                    onChange={handleChange}
                    placeholder="Message"
                />
                <br/>
                <button>Send Email</button>
            </form>
        </div>
    )
}