import React, {useContext} from 'react'
import {BattleContext} from '../context/BattleProvider'
import Unit from './Unit'

export default function UnitList() {
    const {units} = useContext(BattleContext)
    const unitSelectList = units.map(unit => <Unit key={unit._id} unit={unit} selectList={false}/>)
    return(
        <div style={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr", width:"auto", border:"5px solid #080808", backgroundColor:"#121212"}}>
            {unitSelectList}
        </div>
    )
}