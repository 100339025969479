import React from 'react'

export default function EquipGear(props){
    const { unit, weapons, armor, equipWeapon, equipArmor, wepArm } = props
    let wepArmList = []
    if(wepArm === "weapon"){
        wepArmList = weapons.map(weapon => {
            return(<div key={weapon._id}>
                <span style={{fontSize: "12px"}}>{weapon.name}</span>
                <button onClick={() => equipWeapon(unit, weapon)}>EQ</button>
            </div>)
        })
    } else if(wepArm === "armor"){
        wepArmList = armor.map(armor => {
            return(<div key={armor._id}>
                <span style={{fontSize: "12px"}}>{armor.name}</span>
                <button style={{right:0}} onClick={() => equipArmor(unit, armor)}>EQ</button>
            </div>)
        })
    }
    return(
        <div>
            <h2>{wepArm === "weapon" ? "Weapons" : "Armor"}</h2>
            <div>
                {wepArmList}
            </div>
        </div>
    )
}