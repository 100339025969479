import React,{ useState, useEffect, useCallback } from 'react'
import units from "./temp-data/units.json";
import weapons from "./temp-data/weapons.json";
import armor from "./temp-data/armor.json";
/*import axios from 'axios'

const battleAxios = axios.create()
battleAxios.interceptors.request.use(config => {
    const token = localStorage.getItem("token")
    config.headers.Authorization = `Bearer ${token}`
    return config
})*/
export const BattleContext = React.createContext()

export default function BattleProvider(props){
    const initUnitList = {
        units: [],
        weapons: weapons.weapons,
        armor: armor.armor
    }
    const [unitListState, setUnitListState] = useState(initUnitList)

    function equipArmor(unitE, newArmor){
        let updatedUnit = unitE
        if (unitE.inventory.equip.armor.name !== ""){
            if (unitE.inventory.equip.armor.wtty === "heavy"){
                updatedUnit.prclcstats.aspd = unitE.prclcstats.aspd + Math.floor(unitE.inventory.equip.armor.wt / 2)
            }
            if (unitE.inventory.equip.armor.eff.hasOwnProperty("flx1")){
                updatedUnit.prclcstats.avo = Math.ceil(((updatedUnit.gstats.spd*0.8)+(updatedUnit.gstats.per*0.2))+(updatedUnit.gstats.spd*((updatedUnit.gstats.per-3)/5)))
            }
            updatedUnit.prclcstats.efwrd = updatedUnit.gstats.wrd
            updatedUnit.prclcstats.efrls = updatedUnit.gstats.rsl
        }
        updatedUnit.inventory.equip.armor = newArmor
        if (newArmor.eff.hasOwnProperty("mgdf1")){
            if (unitE.gstats.wrd * newArmor.eff.mgdf1.val >= newArmor.eff.mgdf1.bsval){
                updatedUnit.prclcstats.efwrd = unitE.gstats.wrd * newArmor.eff.mgdf1.val
            } else {
                updatedUnit.prclcstats.efwrd = newArmor.eff.mgdf1.bsval
            }
        }
        if (newArmor.eff.hasOwnProperty("flx1")){
            updatedUnit.prclcstats.avo = updatedUnit.prclcstats.avo + newArmor.eff.flx1.avo
        }
        if (updatedUnit.inventory.equip.armor.wtty === "heavy"){
            updatedUnit.prclcstats.aspd = updatedUnit.prclcstats.aspd - Math.floor(newArmor.wt / 2)
        }
        if (unitE.gstats.rsl * newArmor.val >= newArmor.bsval){
            updatedUnit.prclcstats.efrsl = Math.floor(unitE.gstats.rsl * newArmor.val)
        } else {
            updatedUnit.prclcstats.efrsl = newArmor.bsval
        }
        setUnitListState(prevState => ({
            ...prevState,
            units: prevState.units.map(unit => {
                if(unitE._id === unit._id){
                    return updatedUnit
                } else {
                    return unit
                }
            })
        }))
    }
    function equipWeapon(unitE, newWeapon){
        let updatedUnit = unitE
        if (updatedUnit.inventory.equip.weapon.name !== ""){
            updatedUnit.prclcstats.atk = unitE.gstats.str - unitE.inventory.equip.weapon.mt
            updatedUnit.prclcstats.aspd = unitE.prclcstats.aspd + unitE.inventory.equip.weapon.efwt
            updatedUnit.prclcstats.hit = unitE.prclcstats.hit - unitE.inventory.equip.weapon.hit
            updatedUnit.prclcstats.crit = unitE.prclcstats.crit - unitE.inventory.equip.weapon.crit
        }
        updatedUnit.inventory.equip.weapon = newWeapon
        if (newWeapon.eff.hasOwnProperty("mgsk")){
            updatedUnit.prclcstats.atk = unitE.gstats.mag + newWeapon.mt
        } else {
            updatedUnit.prclcstats.atk = unitE.gstats.str + newWeapon.mt
        }
        updatedUnit.prclcstats.hit = unitE.prclcstats.hit + newWeapon.hit
        updatedUnit.prclcstats.crit = unitE.prclcstats.crit + newWeapon.crit
        updatedUnit.prclcstats.aspd = updatedUnit.prclcstats.aspd - newWeapon.efwt
        setUnitListState(prevState => ({
            ...prevState,
            units: prevState.units.map(unit => {
                if(unitE._id === unit._id){
                    return updatedUnit
                } else {
                    return unit
                }
            })
        }))
    }
    const getUnits = useCallback(function(){
        //get blah blah
        const newUnitsList = units.units.map(unit => { 
            return ({
                name: unit.name,
                gstats: {
                    bshp: unit.stats[1][0], str: unit.stats[1][1], spd: unit.stats[1][2], dex: unit.stats[1][3], rsl: unit.stats[1][4],
                    mag: unit.stats[1][5], wrd: unit.stats[1][6], gint: unit.stats[1][7], cha: unit.stats[1][8], con: unit.stats[1][9],
                    per: unit.stats[1][10], wlp: unit.stats[1][11], lck: unit.stats[1][12]},
                prclcstats: {
                    efhp: Math.ceil(unit.stats[1][0] + (((unit.stats[1][9] - 3) * 1.5 * unit.stats[1][0]) * 0.1)),
                    atk: 0, aspd: unit.stats[1][2], efrsl: unit.stats[1][4], efwrd: unit.stats[1][6],
                    hit: Math.ceil((unit.stats[1][3]*1.5)+(unit.stats[1][3]*((unit.stats[1][10] - 4)/5))), 
                    avo: Math.ceil(((unit.stats[1][2]*0.8)+(unit.stats[1][10]*0.2))+(unit.stats[1][2]*((unit.stats[1][10]-3)/5))), 
                    crit: Math.floor((unit.stats[1][3]/4)+(unit.stats[1][3]*((unit.stats[1][10]+unit.stats[1][12]-5)/10))),
                    critdmg: Math.floor((unit.stats[1][3])+(unit.stats[1][3]*((unit.stats[1][10]+unit.stats[1][12]-5)/10))),
                    dge: Math.floor((unit.stats[1][10]+unit.stats[1][12])*2)},
                inventory: {
                    equip: {
                        weapon: {
                            mt: 0, hit: 0, crit: 0,
                            rng: 1, wt: 0, efwt: 0,
                            eff: {}, name: ""
                        }, armor: {
                            val: 1, bsval: 0,
                            wt: 0, wtty: "",
                            eff: {}, name: ""
                        }, accessory: {
                            wt: 0,
                            eff: {}, name: ""
                        }
                    },
                    bag: {
                        slot1: {
                            type: "",
                            item: {}
                        }, 
                        slot2: {
                            type: "",
                            item: {}
                        }, 
                        slot3: {
                            type: "",   
                            item: {}
                        }
                    },
                    acrrwt: 0
                },
                _id: unit._id
            })
        })
        setUnitListState(prevState => ({
            ...prevState,
            units: newUnitsList
        }))
    }, [])
    useEffect(() => {
        getUnits()
    }, [getUnits])
    return(
        <BattleContext.Provider value={{...unitListState, getUnits, equipArmor, equipWeapon}} >
            {props.children}
        </BattleContext.Provider>
    )
}

/*
    function runDefaultEquips(unit){
        if(unit.hasOwnProperty('prefs')){
            if (unit.prefs.weapon !== ""){
                let wep = {}
                unitListState.weapons.map(weapon => {
                    if (weapon.name === unit.prefs.weapon){
                        wep = weapon
                    }
                })
                equipWeapon(unit, wep)
            }
            if (unit.prefs.armor !== ""){
                let arm = {}
                unitListState.armor.map(armor => {
                    if (armor.name === unit.prefs.armor){
                        arm = armor
                    }
                })
                equipArmor(unit, arm)
            }
        }
    }
*/