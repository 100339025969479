import React, { useEffect, useState } from 'react'
import UnitSelectList from './UnitSelectList'

export default function BattleTest(){
    const initUnits = [{
        name: "N/A", weapon: "", armor: "",
        maxhp: 0, hp: 0, def: 0, res: 0,
        hit: 0, avo: 0, dge: 0, 
        atk: 0, aspd: 0, crit:0, critdmg:0
    },{
        name: "N/A", weapon: "", armor: "",
        maxhp: 0, hp: 0, def: 0, res: 0,
        hit: 0, avo: 0, dge: 0,
        atk: 0, aspd: 0, crit:0, critdmg:0
    }]
    const [slctedUnits, setSlctedUnits] = useState({unitA:undefined, unitB:undefined})
    const [units,setUnits] = useState(initUnits)
    const [btlOutcome, setBtlOutcome] = useState([0,0,"null","null","null"])
    const [refreshUnits, setRefresh] = useState(false)
    const [button, toggleButton] = useState(true)
    useEffect(() => {
        if (slctedUnits.unitA && slctedUnits.unitB !== undefined){
            setUnits([{
                name: slctedUnits.unitA.name, weapon: slctedUnits.unitA.inventory.equip.weapon.name, armor: slctedUnits.unitA.inventory.equip.armor.name,
                maxhp: slctedUnits.unitA.prclcstats.efhp, hp: slctedUnits.unitA.prclcstats.efhp, def: slctedUnits.unitA.prclcstats.efrsl, mgdef: slctedUnits.unitA.prclcstats.efwrd,
                hit: slctedUnits.unitA.prclcstats.hit, avo: slctedUnits.unitA.prclcstats.avo, dge: slctedUnits.unitA.prclcstats.dge,
                atk: slctedUnits.unitA.prclcstats.atk, aspd: slctedUnits.unitA.prclcstats.aspd, crit: slctedUnits.unitA.prclcstats.crit, critdmg: slctedUnits.unitA.prclcstats.critdmg
            },{
                name: slctedUnits.unitB.name, weapon: slctedUnits.unitB.inventory.equip.weapon.name, armor: slctedUnits.unitB.inventory.equip.armor.name,
                maxhp: slctedUnits.unitB.prclcstats.efhp, hp: slctedUnits.unitB.prclcstats.efhp, def: slctedUnits.unitB.prclcstats.efrsl, mgdef: slctedUnits.unitB.prclcstats.efwrd,
                hit: slctedUnits.unitB.prclcstats.hit, avo: slctedUnits.unitB.prclcstats.avo, dge: slctedUnits.unitB.prclcstats.dge,
                atk: slctedUnits.unitB.prclcstats.atk, aspd: slctedUnits.unitB.prclcstats.aspd, crit: slctedUnits.unitB.prclcstats.crit, critdmg: slctedUnits.unitB.prclcstats.critdmg
            }])
        }
    }, [refreshUnits, slctedUnits.unitA, slctedUnits.unitB])

    function rng(min,max){
        return Math.floor(Math.random() * (max - min + 1)) + min
    }
    function hitCheck(char, opponent){
        let charHit = char.hit
        let oppAvoid = opponent.avo
        let charCrit = char.crit
        let oppDodge = opponent.dge
        let target = charHit - oppAvoid
        let critTarget = charCrit - oppDodge
        let h = rng(1, 100)
        if(h <= target){
            if (h <= critTarget){
                return 3
            } else {
                return 2
            }
        } else {
            return 1
        }
    }
    function damageCheck(char, opponent){
        let hit = hitCheck(char,opponent)
        let damage = char.atk - opponent.def
        if (damage <= 0){
            damage = 1
        }
        if (hit === 1){
            return(-1)
        } else if (hit === 3){
            damage = Math.floor(damage * (2 + (char.critdmg / 100)))
            return(damage)
        } else {
            return(damage)
        }
    }
    function runBattle (agressor, defender){
        var agrHp = agressor.hp
        var defHp = defender.hp
        let res = damageCheck(agressor, defender)
        var outcome = [agrHp,defHp,"null","null","null"]
        if (defHp <= 0){
            setBtlOutcome(outcome)
            setUnits(prevState => ([{...prevState[0], hp:agrHp,}, {...prevState[1], hp:defHp,}]))
            return(outcome)
        }
        if (res > 0) {
            defHp = defHp - res
            outcome = [agrHp,defHp,res,"null","null"]
        } else {
            outcome = outcome = [agrHp,defHp,"Miss","null","null"]
        }
        if (defHp <= 0){
            setBtlOutcome(outcome)
            setUnits(prevState => ([{...prevState[0], hp:agrHp,}, {...prevState[1], hp:defHp,}]))
            return(outcome)
        }
        res = damageCheck(defender, agressor)
        if (res > 0) {
            agrHp = agrHp - res
            outcome = [agrHp,defHp,outcome[2],res,"null"]
        } else {
            outcome = [agrHp,defHp,outcome[2],"Miss","null"]
        }
        if (agrHp <= 0){
            setBtlOutcome(outcome)
            setUnits(prevState => ([{...prevState[0], hp:agrHp,}, {...prevState[1], hp:defHp,}]))
            return(outcome)
        }
        if (agressor.aspd > defender.aspd){
            res = damageCheck(agressor, defender)
            if (res > 0) {
                defHp = defHp - res
                outcome = [agrHp,defHp,outcome[2],outcome[3],res]
            } else {
                outcome = [agrHp,defHp,outcome[2],outcome[3],"Miss"]
            }
            setBtlOutcome(outcome)
            setUnits(prevState => ([{...prevState[0], hp:agrHp,}, {...prevState[1], hp:defHp,}]))
            return(outcome)
        } else if (defender.aspd > agressor.aspd){
            res = damageCheck(defender, agressor)
            if (res > 0) {
                agrHp = agrHp - res
                outcome = [agrHp,defHp,outcome[2],outcome[3],res]
            } else {
                outcome = [agrHp,defHp,outcome[2],outcome[3],"miss"]
            }
            setBtlOutcome(outcome)
            setUnits(prevState => ([{...prevState[0], hp:agrHp,}, {...prevState[1], hp:defHp,}]))
            return(outcome)
        } else {
            setBtlOutcome(outcome)
            setUnits(prevState => ([{...prevState[0], hp:agrHp,}, {...prevState[1], hp:defHp,}]))
            return(outcome)
        }
    }
    /*function runFullBattle(initAgressor, initDefender){
        var fullBtlRes = []
        fullBtlRes[0] = runBattle(initAgressor, initDefender)
        var cont = 0
        var turn = 1
        while(cont < 2){
            if(btlOutcome[0] > 0 && btlOutcome[1] > 0 && turn <= 5){
                console.log(btlOutcome[0])
                console.log(btlOutcome[1])
                if (cont === 0){
                    fullBtlRes.push(runBattle(initDefender, initAgressor))
                } else {
                    fullBtlRes.push(runBattle(initAgressor, initDefender))
                }
            } else {
                cont = 2
                turn++
            }
            if(cont === 0){
                cont = 1
                turn++
            } else if(cont === 1) {
                cont = 0
                turn++
            }
        }
        console.log(fullBtlRes)
    }*/
    
    const dualCharacters = units.map((unit, index) => {
        return (<div key={index} style={{display:"grid", padding:0, gridTemplateRows:"20px 80px", gridTemplateColumns:"1fr 1fr", width:"98%", height:"100px", 
        border:"5px solid #141466", backgroundColor:"#2929CC", margin:0, borderRadius:'50px 15px 50px 15px'}}>
            <ul style={{gridRow:"1/2", gridColumn:"1/3", height:20, borderBottom:"3px solid #141466", display:"flex", alignItems:"center", listStyle:"none"}}>
                <h2 style={{position:"relative", bottom:"5px"}}>{unit.name} | Current HP:{unit.hp < 0 ? 0 : unit.hp}</h2>
            </ul>
            <div style={{gridRow:"2/3", gridColumn:"1/3", height:80, display:"grid", gridTemplateColumns:"1fr 1fr", gridTemplateRows:"30px 50px", paddingLeft: 2}}>
                <h3 style={{gridRows:"1/2", gridColumn:"1/3"}}>WEAPON: {unit.weapon === "" ? "N/A" : unit.weapon} - ARMOR: {unit.armor === "" ? "N/A" : unit.armor}</h3>
                <h3 style={{gridRows:"2/3", gridColumn:"1/2", fontSize:16}}>HP:{unit.maxhp} | DEF:{unit.def} | RES:{unit.mgdef} <br /> ATK:{unit.atk} | ASPD:{unit.aspd < 0 ? 0 : unit.aspd}</h3>
                <p style={{gridRows:"2/3", gridColumn:"2/3", fontSize:14}}>HIT:{unit.hit} | AVO:{unit.avo} | DGE:{unit.dge} <br /> CRIT:{unit.crit} | CRITDMG:{unit.critdmg}</p>
            </div>
        </div>)
    })

    return(
        <div style={{backgroundColor:"#222222", border:"5px solid #080808"}}>
            <div style={{display:"grid", gridTemplateColumns:"1fr 1fr", width:"auto", backgroundColor:"#121212"}}>
                {dualCharacters}
            </div>
            <div style={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr 1fr 1fr 1fr", gridTemplateRows:"1fr 1fr 1fr", width:"auto", border:"5px solid #121212"}}>
                <h1 style={{gridRow:"1/2",gridColumn:"1/7"}}>Engagment Results</h1>
                <h2 style={{gridRow:"2/3",gridColumn:"1/4"}}>Agressor HP: {btlOutcome[0] < 0 ? 0 : btlOutcome[0]}</h2>
                <h2 style={{gridRow:"2/3",gridColumn:"4/7"}}>Defender HP: {btlOutcome[1] < 0 ? 0 : btlOutcome[1]}</h2>
                <h2 style={{gridRow:"3/4",gridColumn:"1/3"}}>Hit 1: {btlOutcome[2]}</h2>
                <h2 style={{gridRow:"3/4",gridColumn:"3/5"}}>Hit 2: {btlOutcome[3]}</h2>
                <h2 style={{gridRow:"3/4",gridColumn:"5/7"}}>Hit 3: {btlOutcome[4]}</h2>
            </div>
            <div style={{position:"relative", textAlign:"center", backgroundColor:"#121212"}}>
                <button onClick={()=> {runBattle(units[0],units[1])}}>Run Skirmish!</button>
                {/*<button onClick={()=> {console.log(runFullBattle(units[0],units[1]))}}>Run Battle!</button>*/}
                <button onClick={() => setRefresh(!refreshUnits)}>Refresh</button>
                <button onClick={() => toggleButton(!button)}>{button ? "Close Unit Select" : "Open Unit Select"}</button>
            </div>
            {button && <UnitSelectList setSlctedUnits={setSlctedUnits}/>}
        </div>
    )
}