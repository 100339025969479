import React from 'react'
import { Link } from 'react-router-dom'

const linkStyle = {
    color: "#ABABAB",
    textDecoration: "none"
}

const navBar = {
    position: "absolute",
    display: "grid",
    top: "0px",
    backgroundColor: "#2D2D2D",
    width: "100%",
    listStyle: "none"
}
function Navigation(){
    return(
        <nav className="nav-bar" style={navBar}>
            <ul>
                <li>
                    <Link to="/" style={linkStyle}> About Me </Link>
                </li>
                <li>
                    <Link to="/projects" style={linkStyle}> My Projects </Link>
                </li>
                <li className="battle-test">
                    <Link to="/projects/battleTest" style={linkStyle}> Battle Project </Link>
                </li>
            </ul>
            {/*
            <ul style={{width:"100%",gridRow:"1/2",gridColumn:"2/3"}}>
                <li style={{position:"relative",right:"0px"}}>
                    <a href="https://github.com/Aeroflou" target="_blank" rel="noopener noreferrer"> 
                        <img src="https://cdn-icons-png.flaticon.com/512/25/25231.png" alt="github" style={{height:"24px",width:"24px"}}/>
                    </a>
                </li>
                <li style={{position:"relative",right:"0px"}}>
                    <a href="https://www.linkedin.com/in/austinbyas" target="_blank" rel="noopener noreferrer"> 
                        <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="linkedin" style={{height:"24px",width:"24px"}}/> 
                    </a>              
                </li>
            </ul>
            */}
        </nav>
    )
}

export default Navigation

/*
from first ul
style={{gridRow:"1/2",gridColumn:"1/2"}}


from navBar
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr"
*/